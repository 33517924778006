import { AppDispatch, GetState } from '../store';
import { checkReportAPI } from '../../services/CheckReportService';
import qs from 'qs';
import { handleError } from '../slices/errors.slice';
import { UpdateReportError } from '../../check/errors';
import { initializeState } from './init-state';
import { initializeProcessingStep } from './init-current-step';
import { PersonaInformation } from '../slices/persona.slice';
import { selectDeactivatedConsentForwardingAndResults } from '../slices/flags.slice';

export const putPersonaData = (persona: PersonaInformation) => {
	return async (dispatch: AppDispatch, getState: GetState) => {
		const deactivatedConsent = selectDeactivatedConsentForwardingAndResults(getState());
		const payload = createPersonaPayload(persona, deactivatedConsent);
		try {
			const response = await checkReportAPI.putPersonaData(payload);
			const { errors } = response.data;

			if (errors) {
				dispatch(processUpdateErrors(errors));
				return;
			}

			dispatch(initializeProcessingStep());
		} catch (error: any) {
			dispatch(handleError(error));
		}
	};
};

export type PersonaInformationPayload = PersonaInformation & {
	acceptTermsAndConditions: 1;
	acceptPrivacyPolicy: 1 | 0;
	status: 'completed';
};

function createPersonaPayload(personaInformation: PersonaInformation, privacyDisabled: boolean) {
	const payload: PersonaInformationPayload = {
		...personaInformation,
		acceptTermsAndConditions: 1,
		acceptPrivacyPolicy: privacyDisabled ? 0 : 1,
		status: 'completed'
	};

	return qs.stringify(payload);
}

const ALREADY_PROCESSED_ERROR = 'Check is being processed, editing user data at this stage is not allowed';

function processUpdateErrors(errors: Array<string>) {
	return (dispatch: AppDispatch) => {
		const error = errors[0];

		if (error === ALREADY_PROCESSED_ERROR) {
			dispatch(initializeState());
			return;
		}

		dispatch(handleError(new UpdateReportError(errors)));
	};
}
